@import "../../styles/mixins";
@import "../../styles/variables";
.title{
  margin: 0 0 30px;
  @include text($titleFontFamily, 36, 1.5, 100);
  text-transform: capitalize;
  letter-spacing: 5px;
  position: relative;
  text-align: center;
    color: $textColor;
  &::before{
    content: "";
    position: absolute;
    background: #ffbf00;
    width: 60px;
    height: 2px;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, 50%);
  }
}

