@import "../../styles/mixins";
@import "../../styles/variables";
.btn{
  cursor: pointer;
  border: 1px solid black;
  text-decoration: none;
  background: linear-gradient(234deg, #ffbf00, #ffbf00, #000);
  border-radius: 100px;
  @include text($titleFontFamily, 15px, 1.5, 500);
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 8px 20px;
  max-width: 160px;
  width: 100%;
  color: $textColor;
  outline: none;
  position: relative;
  z-index: 0;
  &:hover{
    text-decoration: none;
    color: white;
    background: radial-gradient(ellipse farthest-corner, #ffbf00 0%, #000 90%)
  }
}
