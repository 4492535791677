
@import '../common/styles/mixins';
@import '../common/styles/variables';

.logoCompany {
 width: 100%;
 @include flex(row, flex-start, center, nowrap);
 .logoText {
  @include text($titleFontFamily, 100, 1.4, 500);
  text-transform: capitalize;
  letter-spacing: 2px;
  color: $textColor;
 }
}