@import './variables';

@mixin text($fontFamily,$fontSize,$lineHeight, $fontWeight ){
  font-family: $fontFamily ;
  font-size: $fontSize;
  line-height: $lineHeight;
  font-weight: $fontWeight;

}

@mixin container(){
  width: 80%;
  margin: 0 auto;
}

@mixin flex($direction, $justifyContent, $alignItems, $wrap){
  display: flex ;
  flex-direction: $direction;
  justify-content: $justifyContent;
  align-items: $alignItems;
  flex-wrap: $wrap;
}
@mixin bgSettings(){
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

}