@import '../common/styles/mixins';
@import '../common/styles/variables';

.nav{
  width: 500px;
  @include flex(row, space-between, stretch, nowrap);
  a{
    @include text($titleFontFamily, 16px, 1.2, 400);
    cursor: pointer;
    color:$textColor;
    text-decoration: none;
    &:hover{
      text-decoration: none;
      color:$activeColor;
    }
  }
}
