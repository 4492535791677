@import '../common/styles/mixins';
@import '../common/styles/variables';
.contactsBlock {
  text-align: center;
  .contacts {
        margin: 0 0 30px;
        @include text($titleFontFamily, 100, 1.4, 500);
        text-transform: capitalize;
        letter-spacing: 3px;
        position: relative;
        text-align: center;
        color: $textColor;
        &::before {
          content: "";
          position: absolute;
          background: $bgColor;
          width: 55px;
          height: 1px;
          bottom: -30px;
          left: 50%;
          transform: translate(-50%, 50%);
        }
    }
}