@import '../common/styles/mixins';
@import '../common/styles/variables';

.header {
    .container {
        padding: 20px;
        @include container();
        @include flex(row, flex-end, center, nowrap);
        background: transparent;
        position: fixed;
        z-index: 9999;
        left: 0;
        right: 0;
    }
   .headerImg{
       width: 100%;
       height: 540px;
   }
}